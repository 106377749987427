import {
  Container,
  Paper,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { ThumbUp } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      padding: theme.spacing(10),
    },
    icon: {
      color: theme.palette.primary.main,
    },
    zsr: {
      padding: theme.spacing(8),
      textAlign: 'center',
    },
    zsrCaption: {
      fontSize: '1.5rem',
      marginTop: theme.spacing(1),
    },
  })
)

const ZeroResults = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Paper className={classes.zsr} elevation={4}>
        <ThumbUp fontSize="large" className={classes.icon} />
        <Typography className={classes.zsrCaption} variant="body1">
          You're all set for today.
        </Typography>
      </Paper>
    </Container>
  )
}

export { ZeroResults }
