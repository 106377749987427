import React from 'react'

import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

import { useControls } from './search'

export const HungryCheckbox = () => {
  const { hungry, setHungry } = useControls()

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={hungry}
            color="secondary"
            name="hungry"
            onChange={({
              target: { checked },
            }: React.ChangeEvent<HTMLInputElement>) => {
              setHungry(checked)
            }}
          />
        }
        label="hungry"
      />
    </FormGroup>
  )
}
