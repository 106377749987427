import { Photo, isPhotoData } from './core'
import { ApiRecord, UseQueryResult, useRecords } from './core'

type UrlsByPhotoId = Record<string, string>
type GetStringById = (id: string) => string | undefined

const reducer = (obj: UrlsByPhotoId, current: ApiRecord<Photo>) => {
  const specimenId = current.fields.Specimens[0]
  const thumbnails = current.fields.Attachments?.[0].thumbnails
  if (thumbnails) {
    return { ...obj, [specimenId]: thumbnails.large.url }
  } else {
    return obj
  }
}

export const usePhotos = (): {
  getPhotoUrl: GetStringById
} => {
  const { data }: UseQueryResult = useRecords<Photo>('Photos')
  if (!isPhotoData(data)) return { getPhotoUrl: () => undefined }

  const records: ApiRecord<Photo>[] = data.records
  const byId: UrlsByPhotoId = records.reduce(reducer, {})
  return {
    getPhotoUrl: (id: string): string => byId[id],
  }
}
