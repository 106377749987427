interface AirtableData<T> {
  records: T[]
}

type ApiRecord<FieldsType> = {
  createdTime: string
  fields: FieldsType
  id: string
}

const isApiRecord = <FieldsType>(
  arg: unknown
): arg is ApiRecord<FieldsType> => {
  return (arg as ApiRecord<FieldsType>)?.fields !== undefined
}

const hasRecords = <DataType>(arg: unknown): arg is AirtableData<DataType> => {
  const maybe = arg as AirtableData<DataType>
  return maybe?.records !== undefined && maybe?.records.length > 0
}

export type { AirtableData, ApiRecord }
export { hasRecords, isApiRecord }
