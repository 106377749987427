import { SpecimenRecord } from '../api'
import { searchExcludesRecord } from './helpers'
import { useHungry } from './useHungry'
import { useSpecies } from '../api'

type Filters = {
  skipDisplay: boolean
}

export function useFilters(phrase: string, record: SpecimenRecord): Filters {
  const { getName: getSpeciesName } = useSpecies()
  const { hungryExcludesRecord } = useHungry()
  const { fields } = record

  const { Hungry, Nickname, Public, Species } = fields
  if (hungryExcludesRecord(Hungry)) return { skipDisplay: true }

  if (Public !== 'TRUE') return { skipDisplay: true }

  const speciesId = Species?.[0]
  const speciesName = getSpeciesName(speciesId)
  if (searchExcludesRecord(phrase, speciesName, Nickname))
    return { skipDisplay: true }

  return { skipDisplay: false }
}
