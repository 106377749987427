import React from 'react'

import MenuIcon from '@material-ui/icons/Menu'
import { IconButton } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(-1),
    },
  })
)

export const MenuButton = () => {
  const classes = useStyles()
  return (
    <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
    >
      <MenuIcon />
    </IconButton>
  )
}
