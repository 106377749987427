import { UseQueryResult, useQuery } from 'react-query'
import { AirtableData, ApiRecord, hasRecords } from './airtable'
import { ID } from './ID'

import { apiKey, baseId } from './credentials'

type QueryParameters = {
  [key: string]: string
}

type ApiParams = {
  maxRecords?: number
  table: string
  view?: string
}

const getQueryString = (p: QueryParameters): string =>
  Object.entries(p)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

const getData = async <DataType>(url: string): Promise<DataType> =>
  fetch(url).then((response) => response.json())

const getRecords = <DataType>({
  maxRecords,
  table,
  view = 'Grid%20view',
  ...rest
}: ApiParams): Promise<DataType> => {
  let options: QueryParameters = { ...rest, api_key: apiKey, view }
  if (maxRecords) {
    options = { ...options, maxRecords: maxRecords.toString() }
  }
  const q = getQueryString(options)
  const url = `https://api.airtable.com/v0/${baseId}/${table}?${q}`
  return getData<DataType>(url)
}

const useRecords = <DataType>(name: string, options = {}): UseQueryResult =>
  useQuery<DataType, Error>(name, () =>
    getRecords<DataType>({ table: name, ...options })
  )

interface ImageProperties {
  url: string
  width: number
  height: number
}

interface Attachment {
  id: ID
  url: string
  filename: string
  size: number
  type: string
  width?: number
  height?: number
  thumbnails: {
    small: ImageProperties
    large: ImageProperties
    full: ImageProperties
  }
}

interface Photo {
  Attachments: Attachment[]
  Specimens: ID[]
}

const isPhoto = (arg: unknown): arg is Photo => {
  return (arg as Photo)?.Specimens !== undefined
}

type PhotoData = {
  records: ApiRecord<Photo>[]
}

const isPhotoData = (data: unknown): data is PhotoData => {
  const firstRecord = (data as PhotoData)?.records?.[0]?.fields
  return hasRecords(data) && isPhoto(firstRecord)
}

interface Specimen {
  [key: string]: string | ID[] | Photo[]

  Adopted: ID[]
  Hungry: 'TRUE' | 'FALSE'
  ID: string
  Location: string
  Nickname: string
  Overdue: string
  Photos: Photo[]
  Species: ID[]
  Status: string
  Waterings: ID[]
  ['Moisture Checks']: ID[]
}

type Specimens = {
  records: Specimen[]
}

type SpecimenRecord = ApiRecord<Specimen>

const isSpecimen = (arg: unknown): arg is Specimen => {
  return (arg as Specimen)?.Nickname !== undefined
}

const isSpecimens = (arg: unknown): arg is Specimens => {
  const firstRecord = (arg as Specimens)?.[0]?.fields
  return isSpecimen(firstRecord)
}

const isSpecimensData = (
  data: unknown
): data is AirtableData<SpecimenRecord> => {
  return hasRecords(data) && isSpecimens(data.records)
}

export * from './airtable'
export * from './credentials'
export * from './types'
export { hasRecords } from './airtable'
export type { ApiRecord } from './airtable'
export type { Attachment }
export type { Photo }
export type { Specimen, SpecimenRecord }
export type { UseQueryResult }
export { getRecords, useRecords }
export { isPhotoData }
export { isSpecimens, isSpecimensData }
