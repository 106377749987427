import React from 'react'

import { AppBar as MuiAppBar, Toolbar } from '@material-ui/core'

import { HungryCheckbox } from './HungryCheckbox'
import { MenuButton } from './MenuButton'
import { Search } from './search'
import { Title } from './Title'

export const Header = () => (
  <header>
    <MuiAppBar color="primary" position="sticky">
      <Toolbar>
        <Title />
        <HungryCheckbox />
        <Search />
        <MenuButton />
      </Toolbar>
    </MuiAppBar>
  </header>
)
