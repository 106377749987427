import * as React from 'react'
import { Chip } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hungry: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  })
)

const HungryChip = ({ hungry }: { hungry: boolean }) => {
  const classes = useStyles()
  if (!hungry) return null

  return <Chip className={classes.hungry} label="Hungry" />
}

export { HungryChip }
