export function searchExcludesRecord(
  phrase: string,
  speciesName: string,
  nickname: string
) {
  return (
    phrase &&
    !caseInsensitiveIncludes(nickname, phrase) &&
    !caseInsensitiveIncludes(speciesName, phrase)
  )
}

function caseInsensitiveIncludes(haystack: string, needle: string) {
  return haystack.toLowerCase().includes(needle.toLowerCase())
}
