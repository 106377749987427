import { Species, isSpecies } from './Species'

export interface SpeciesRecord {
  createdTime: string
  id: string
  fields: Species
}

export const isSpeciesRecord = (arg: unknown): arg is SpeciesRecord => {
  const fields = (arg as SpeciesRecord)?.fields
  return isSpecies(fields)
}
