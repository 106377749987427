import { hasRecords, UseQueryResult, useRecords } from './core'
import { Fields } from './types'
import { Species } from './Species'
import { isSpeciesRecord } from './SpeciesRecord'

type SpeciesById = Record<string, Species>

const reducer = (obj: SpeciesById, current: Species) => {
  const { id } = current
  return { ...obj, [id]: current }
}

export const useSpecies = (): {
  data: unknown
  error: unknown
  isError: boolean
  getName: (id: string) => string
  isLoading: boolean
} => {
  const { data, ...rest }: UseQueryResult = useRecords('Species')
  if (!hasRecords<Species>(data)) {
    return { getName: () => '', data, ...rest }
  }
  if (!isSpeciesRecord(data.records[0])) {
    return { getName: () => '', data, ...rest }
  }

  const records: Species[] = data.records
  const byId: SpeciesById = records.reduce(reducer, {})
  const getName = (id: string): string => {
    const fields = byId[id]?.fields
    if (fields) {
      return (fields as Fields).Name as string
    } else {
      return ''
    }
  }
  return { getName, data, ...rest }
}
