import { useContext } from 'react'

import { ControlsContext } from '../controls'

export function useHungry() {
  const { hungry: showOnlyHungry } = useContext(ControlsContext)
  const hungryExcludesRecord = (hungry: string) =>
    !showOnlyHungry && hungry === 'TRUE'
  return { hungryExcludesRecord }
}
