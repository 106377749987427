import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#002419',
      light: '#38786a',
      main: '#004c3f',
    },
    secondary: {
      contrastText: '#000',
      dark: '#aebfbe',
      light: '#fff',
      main: '#3f6064',
    },
  },
})

export { theme }
