import * as React from 'react'
import { ControlsContext } from './controls'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@material-ui/core'

import { Header } from './Layout/Header'
import { Main } from './Layout/Main'
import { theme } from './theme'

const queryClient = new QueryClient()

const App = () => {
  const [phrase, setPhrase] = React.useState<string>('')
  const [hungry, setHungry] = React.useState<boolean>(true)

  const controls = {
    hungry,
    phrase,
    setHungry,
    setPhrase,
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ControlsContext.Provider value={controls}>
          <Header />
          <Main />
        </ControlsContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
