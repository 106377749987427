import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import { HungryChip } from './HungryChip'
import { Location } from '../Location'
import { OverdueChip } from '../OverdueChip'
import {
  Specimen,
  useLocations,
  usePhotos,
  useSpecies,
  useSpecimens,
} from '../../api'

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      textTransform: 'uppercase',
    },
    media: { minHeight: '67vh' },
    card: {
      backgroundColor: 'white',
    },
    species: {
      fontStyle: 'italic',
    },
  })
)

type Props = {
  specimen: Specimen
}

const PlantCard = ({ specimen }: Props) => {
  const classes = useStyles()
  const { getLocationName } = useLocations()
  const { getName: getSpeciesName } = useSpecies()
  const { getPhotoUrl } = usePhotos()

  if (!specimen) return null

  const {
    Location: loc,
    ID: id,
    Nickname: nickname,
    Species: speciesRecords,
  } = specimen
  const speciesId = speciesRecords[0]
  const speciesName = getSpeciesName(speciesId)
  const photoUrl = getPhotoUrl(id)

  return (
    <Card className={classes.card} elevation={4}>
      <CardActionArea>
        {photoUrl && (
          <CardMedia
            className={classes.media}
            image={photoUrl}
            title={speciesName}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {nickname}
          </Typography>
          <Box fontStyle="italic">
            <Typography gutterBottom variant="body1">
              {speciesName}
            </Typography>
          </Box>
          <Location>{getLocationName(loc)}</Location>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <OverdueChip daysOverdue={Number(specimen.Overdue)} />
        <HungryChip hungry={specimen.Hungry === 'TRUE'} />
      </CardActions>
    </Card>
  )
}

export { PlantCard, useSpecimens }
export { hasRecords } from '../../api'
