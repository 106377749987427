import { Grid } from '@material-ui/core'

import { PlantCard } from '.'
import { useFilters } from './useFilters'

/**
 * PlantItem displays the card for a plant if appropriate according to the
 * current set of filters.
 */
export const PlantItem = ({ phrase, record }) => {
  const { skipDisplay } = useFilters(phrase, record)
  if (skipDisplay) return null

  return (
    <Grid item xs={12} sm={6} md={6} xl={2}>
      <PlantCard specimen={record.fields} />
    </Grid>
  )
}
