import { Dispatch, useContext, SetStateAction, createContext } from 'react'

export function useControls(): SearchControls {
  const context = useContext(ControlsContext)

  if (context === undefined) {
    throw new Error(
      `${this} must be used within a ${ControlsContext.displayName}.Provider`
    )
  }

  return context
}

export type SearchControls = {
  hungry: boolean
  phrase: string
  setHungry: Dispatch<SetStateAction<boolean>>
  setPhrase: Dispatch<SetStateAction<string>>
}

export const ControlsContext = createContext<SearchControls>({
  hungry: false,
  phrase: '',
  setHungry: () => undefined,
  setPhrase: () => undefined,
})
ControlsContext.displayName = 'SearchContext'
