import { UseQueryResult, useRecords } from './core'
import { isApiRecord, hasRecords } from './core'

export interface ApiLocation {
  [key: string]: string
  ID: string
  Name: string
}

export const isLocationRecords = (arg: unknown): arg is ApiLocation[] => {
  const firstRecord = (arg as ApiLocation[])?.[0]?.fields
  return isLocation(firstRecord)
}

export const isLocation = (obj: unknown): obj is ApiLocation => {
  if (
    typeof obj !== 'undefined' &&
    hasRecords<Location>(obj) &&
    isApiRecord<Location>(obj.records[0]) &&
    ((typeof obj.records[0] as unknown) as ApiLocation).Name === 'string'
  )
    return true

  return false
}

type LocationsById = Record<string, ApiLocation>

const reducer = (accumulator: LocationsById, current: ApiLocation) => ({
  ...accumulator,
  [current.ID]: current,
})

const useLocations = (): {
  getLocationName: (id: string) => string | undefined
} => {
  const { data }: UseQueryResult = useRecords('Locations')
  if (!hasRecords<Location>(data) || !isLocationRecords(data.records)) {
    return { getLocationName: () => undefined }
  }

  const records: ApiLocation[] = data.records
  const byId: LocationsById = records.reduce(reducer, {})
  const getLocationName = (id: string): string => byId[id]?.Name
  return { getLocationName }
}

export { useLocations }
