import { Fields } from './types'
import { ID } from './ID'

export interface Species {
  id: ID
  fields: Fields
  Name: string
}

export const isSpecies = (arg: unknown): arg is Species => {
  return (arg as Species)?.Name !== undefined
}
