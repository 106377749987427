import * as React from 'react'

import {
  InputBase,
  Theme,
  createStyles,
  fade,
  makeStyles,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useControls } from '../../controls'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      fontSize: 'calc(10px + 2vmin)',
      flexDirection: 'column-reverse',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    menuButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(-1),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      flexGrow: 1,
    },
  })
)

export const Search = () => {
  const classes = useStyles()
  const { phrase, setPhrase } = useControls()

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={({ target: { value } }) => {
          setPhrase(value)
        }}
        placeholder="Search…"
        value={phrase}
      />
    </div>
  )
}

export * from '../../controls'
