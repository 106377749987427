import {
  Grid,
  LinearProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core'

import { PlantItem, hasRecords, useSpecimens } from '../plants'
import { SpecimenRecord } from '../api'
import { ZeroResults } from './ZeroResults'
import { useControls } from './search'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
    },
    plantsList: {
      flexGrow: 1,
      height: 'calc(100vh - 109px)',
    },
  })
)

export const Main = () => {
  const classes = useStyles()
  const { isLoading, isError, error, data } = useSpecimens()
  const { phrase } = useControls()

  if (isLoading) return <LinearProgress />
  if (isError) return <span>Error: {(error as Error).message}</span>
  if (!hasRecords(data)) return <ZeroResults />

  return (
    <main className={classes.root}>
      <Grid container className={classes.plantsList} spacing={1}>
        {data.records.map((record: SpecimenRecord) => (
          <PlantItem key={record.id} phrase={phrase} record={record} />
        ))}
      </Grid>
    </main>
  )
}
