import { Chip } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overdue: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
  })
)

const OverdueChip = ({ daysOverdue }: { daysOverdue: number }) => {
  const classes = useStyles()
  const overdueLabel = daysOverdue === 1 ? 'day' : 'days'
  if (daysOverdue <= 0) return null

  return (
    <Chip
      className={classes.overdue}
      label={`${daysOverdue} ${overdueLabel} overdue`}
    />
  )
}

export { OverdueChip }
