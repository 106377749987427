import { Specimen, UseQueryResult, useRecords } from './core'

const useSpecimens = (): UseQueryResult => {
  const records = useRecords<Specimen>('Specimens', { view: 'Public' })
  return {
    ...records,
    // TODO ensure specimens is a well-formed array of 0 or more items
    //, specimens: records.data
  }
}

export { useSpecimens }
export type { Specimen }
