import { Typography } from '@material-ui/core'

const Location = ({ children }: { children: React.ReactNode }) => {
  if (!children) return null

  return (
    <Typography
      color="textSecondary"
      component="p"
      style={{ textTransform: 'lowercase' }}
      variant="body2"
    >
      {children}
    </Typography>
  )
}

export { Location }
